import React from 'react'
import './Modal.scss'

export function CloseIcon({onClick}) {
    return (
        <div className="close_icon" onClick={onClick}>
            <svg width="22" height="22" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.192 9.071l7.779-7.778a1.5 1.5 0 0 1 2.12 2.121l-7.777 7.778 7.778 7.779a1.5 1.5 0 1 1-2.121 2.12l-7.779-7.777-7.778 7.778a1.5 1.5 0 1 1-2.121-2.121l7.778-7.779-7.778-7.778a1.5 1.5 0 0 1 2.121-2.121l7.778 7.778z"
                    fill="#FFF" fillRule="nonzero" />
            </svg>
        </div>
    )
}

type ModalPropTypes = {
    isVisible: boolean,
    onCloseClick?: () => void,
    children?: JSX.Element[] | JSX.Element,
    shouldHaveCloseIcon?: boolean,
}
export default function Modal({ isVisible, onCloseClick, children, shouldHaveCloseIcon = true }: ModalPropTypes) {

    const handleOnCloseClick = () => {
        onCloseClick?.()
    }

    return (
        <div hidden={!isVisible} className="modal">
            {shouldHaveCloseIcon && <CloseIcon onClick={handleOnCloseClick}/>}
            {children}
        </div>
    )
}
