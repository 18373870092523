import React from 'react'
/** @jsx jsx */
import { useColorMode, jsx } from "theme-ui"


export default function ColorModeButton() {
    const [colorMode, setColorMode] = useColorMode()
    const isDark = colorMode === `dark`
    const toggleColorMode = (e: any) => {
        setColorMode(isDark ? `light` : `dark`)
    }
    return (
        <button
            sx={{
                variant: `buttons.toggle`,
                fontWeight: `semibold`,
                display: `block`,
                mx: 0,
                mb: 3
            }}
            onClick={toggleColorMode}
            type="button"
            aria-label="Toggle dark mode"
            style={{ borderRadius: '0.5em' }}
        >
            {isDark ? `Light ☀️` : `Dark 🌙`}
        </button>
    )
}
