import React from 'react';
import {text} from "src/lang/resources/en";

function CallMeButton() {

    const handleCallMe = () => {
        //@ts-ignore
        const {Calendly} = window ?? {}
        if (Calendly) {
            const options = {
                url: 'https://calendly.com/idanizi',
                text: 'Schedule time 📞 with me',
                color: '#00a2ff',
                textColor: '#ffffff',
                branding: false
            }
            Calendly.showPopupWidget(options.url, 'PopupWidget', options);
        }
    }


    return (
        <button onClick={handleCallMe}>
            {text.title}
        </button>
    );
}

export default CallMeButton;