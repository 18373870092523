import React, {useState} from 'react';
import {cv_link} from "src/config/config";
import {download_my_cv} from "src/lang/resources/en";
import RequestCVModal from "../RequestCVModal";

function DownloadMyCvButton(props) {

    const [isVisible, setIsVisible] = useState(false)

    const handleDownloadCv = () => {
        setIsVisible(true)
    }

    return (
        <>
            <RequestCVModal isVisible={isVisible} setIsVisible={setIsVisible} showOnMobileEnabled={true}/>
            <button onClick={handleDownloadCv} {...props}>
                {download_my_cv.title}
            </button>
        </>
    );
}

export default DownloadMyCvButton;
