import React from 'react'
import * as device from 'react-device-detect'

// todo: [REFACTOR] move this to constants
export const MOBILE_INNER_WIDTH = 570;

export function isMobile(isMobileFirst = true): boolean{
    if (typeof window === 'undefined') return isMobileFirst;
    return device.isMobile || (window && window.innerWidth < MOBILE_INNER_WIDTH)
}

export function useIsMobile(isMobileFirst: boolean = true) {
    if (typeof window === 'undefined') return isMobileFirst;
    
    const [_isMobile, setIsMobile] = React.useState(isMobileFirst)
    React.useEffect(() => {
        setIsMobile(isMobile())
    }, [window?.innerWidth, device.isMobile])

    return _isMobile;
}

