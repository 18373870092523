export const company = "easyera"

export const open_for_hire_modal = {
    "title": `🎉 I Got Hired At ${company}! 🎉`,

    "subtitle": null,
    // "subtitle": "Update: I'm currently not taking new offers and freezing all my current processes due to an overflow.",

    "footer": "Thank you all for your time ❤️"
}
export const download_my_cv = {
    "title": "Download CV 📄",
}
export const text = {
    "title": "Schedule a Phone Interview 📞",
}

export const request_my_cv_modal = {
    "footer": "Thank you all for your time ❤️"
}
