import React, {useRef} from "react";
import {createPortal} from "react-dom";
import "./RequestCVModal.scss";
import Modal, {CloseIcon} from "../Modal";
import useOnClickOutside from "../../hooks/use-on-click-outside";
import {useIsMobile} from "src/helpers/responsive";
import CallMeButton from "../CallMeButton";
import DownloadMyCvButton from "../DownloadMyCvButton";
import {company, request_my_cv_modal} from "src/lang/resources/en";
import config from "src/config";
import {ConfettiModal} from "../ConfettiModal";

export function InfoIcon() {
    return (
        <span className="info-icon-wrapper">
        <i className="info-icon"/>
    </span>
    );
}


type RequestCVModalProps = {
    isVisible: boolean,
    setIsVisible: (isVisible: boolean) => void,
    showOnMobileEnabled: boolean,
}

const EasyeraLink = () => {
    return (
    <a className={"easyera-link"} href={"https://www.easyera.dev/about"} target={"_blank"}>
        easyera
    </a>
)};

const RequestCVModal: React.FC<RequestCVModalProps> = ({isVisible, setIsVisible, showOnMobileEnabled}) => {
    const isMobile = useIsMobile?.() ?? true;
    if (isMobile && !showOnMobileEnabled) return null;

    const ref = useRef();

    const handleClose = () => {
        setIsVisible(false);
    };

    useOnClickOutside(ref, handleClose);

    return createPortal(
        <ConfettiModal isVisible={isVisible} shouldHaveCloseIcon={false}>
            <section>
                <div className="content" ref={ref}>
                    <CloseIcon onClick={handleClose}/>
                    <header>
                        {/* todo: move all text to en.ts i18n file. */}
                        <h1>
                            🎉 I just founded a new company called <EasyeraLink />! 🎉
                        </h1>
                        <h2 className="flex gap10">
                            <InfoIcon/>
                            <p>
                                Due to security reasons, I do not share my personal CV on this website anymore.<br/>
                                To get it, please email me on idanizi@gmail.com with a suitable reason.<br/>
                                And please share any identification you can deliver so I know above any doubt that
                                you're not a scammer.
                            </p>
                        </h2>
                    </header>
                    <footer>
                        {config.is_open_for_hire ? (
                            <>
                                <DownloadMyCvButton/>
                                <CallMeButton/>
                            </>
                        ) : (
                            <span>{request_my_cv_modal.footer}</span>
                        )}
                    </footer>
                </div>
            </section>
        </ConfettiModal>
        , document.getElementById("___gatsby"));
}

export default RequestCVModal;
