export const seo_default = {
    title: `Idan Izicovich`,
    description: "Full-stack application development engineer"
        + " that creates high-end web & mobile applications for the next generation"
        + " of consumer & business facing companies. Software Engineer B.Sc. by BGU."
        + " Location of main operations: Tel Aviv, Israel.",
    pathname: false,
    image: false,
    children: null,
}

export const cv_link = "https://idanizi-assets.s3.eu-central-1.amazonaws.com/IDAN+IZICOVICH+-+0545921327.pdf"
export const is_open_for_hire = false;
export const is_confetti = true;
export const is_open_for_hire_modal = false;
export const confetti_timeout_ms = 500