import React from "react"
import ProjectCard from '../@lekoarts/gatsby-theme-cara/components/project-card'
import AnimatedArrow from '../@lekoarts/gatsby-theme-cara/components/AnimatedArrow'
import ColorModeButton from '../@lekoarts/gatsby-theme-cara/components/ColorModeButton'
import CallMeButton from "../@lekoarts/gatsby-theme-cara/components/CallMeButton";
import DownloadMyCvButton from "../@lekoarts/gatsby-theme-cara/components/DownloadMyCvButton";

const components = {
  // eslint-disable-next-line react/display-name
  AnimatedArrow: () => (
    <AnimatedArrow />
  ),
  // eslint-disable-next-line react/display-name
  ProjectCard: ({ link, title, bg, children }) => (
    <ProjectCard link={link} title={title} bg={bg}>
      {children}
    </ProjectCard>
  ),
  ColorModeButton: () => (
    <ColorModeButton />
  ),
  CallMeButton,
  DownloadMyCvButton,

}

export default components
