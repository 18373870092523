import React from 'react';
import Modal from "../Modal";
import confetti from "canvas-confetti";
import config from "../../../../config";

const renderConfetti = () => {
    confetti({
        particleCount: 100,
        spread: 70,
        origin: {y: 0.6},
    });
};

type ConfettiModalProps = {
    isVisible: boolean,
    shouldHaveCloseIcon: boolean,
}

export const ConfettiModal: React.FC<ConfettiModalProps> = ({isVisible, shouldHaveCloseIcon, children}) => {
    if (!isVisible) return null;

    React.useLayoutEffect(() => {
        if (config.is_confetti) {
            setTimeout(renderConfetti, config.confetti_timeout_ms);
        }
    })


    return (
        <Modal isVisible={true} shouldHaveCloseIcon={shouldHaveCloseIcon}>
            <>
                {children}
            </>
        </Modal>
    );
}
